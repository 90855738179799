import React, {useState, useEffect} from 'react';
import Carousel from './Carousel';
import Tabs from './Tabs';
import StateTabs from './StateTabs';
import closeIcon from '../../assets/images/gallery/icons/x.svg'
import ImageGrid from './ImageGrid';
import BeforeAfterView from './BeforeAfterView';
import { Helmet } from "react-helmet";

function importAll(r: __WebpackModuleApi.RequireContext): string[] {
  return r.keys().map(key => r(key) as string);
}

const images = {
  "gallery": {
    "kitchens": importAll(require.context('../../assets/images/gallery/kitchens', true, /\.webp$/)),
    "bathrooms": importAll(require.context('../../assets/images/gallery/bathrooms', true, /\.webp$/)),
    "full home": importAll(require.context('../../assets/images/gallery/fullhome', true, /\.webp$/)),
    "extensions": importAll(require.context('../../assets/images/gallery/extensions', true, /\.webp$/)),
    "outdoors": importAll(require.context('../../assets/images/gallery/outdoors', true, /\.webp$/)),
    "roofing": importAll(require.context('../../assets/images/gallery/roofing', true, /\.webp$/))
  },
  "beforeAfter": {
    "kitchens": importAll(require.context('../../assets/images/gallery/beforeAfter/kitchens', true, /\.webp$/)),
    "bathrooms": importAll(require.context('../../assets/images/gallery/beforeAfter/bathrooms', true, /\.webp$/)),
    "full home": importAll(require.context('../../assets/images/gallery/beforeAfter/fullhome', true, /\.webp$/)),
    "extensions": importAll(require.context('../../assets/images/gallery/beforeAfter/extensions', true, /\.webp$/)),
    "outdoors": importAll(require.context('../../assets/images/gallery/beforeAfter/outdoors', true, /\.webp$/)),
    "roofing": importAll(require.context('../../assets/images/gallery/beforeAfter/roofing', true, /\.webp$/))
  }
}

type GalleryName = "kitchens" | "bathrooms" | "full home" | "extensions" | "outdoors" | "roofing";

interface GalleryProps {
  initialGallery: GalleryName;
}

function Gallery({initialGallery}: GalleryProps) {
  const [currentGallery, setCurrentGallery] = useState<string[]>([]);
  const [currentBeforeAfter, setCurrentBeforeAfter] = useState<string[]>([]);

  const [displaySlider, setDisplaySlider] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselImages, setCarouselImages] = useState<string[]>([]);

  const [isBeforeAndAfterView, setIsBeforeAndAfterView] = useState(false);

  const openSliderWithImages = (imagesToDisplay: string[], index: number) => {
    setCarouselIndex(index);
    setCarouselImages(imagesToDisplay);
    setDisplaySlider(true);
  }

  const closeSlider = () => {
    setDisplaySlider(false);
  }

  useEffect(() => {
    setCurrentGallery(images.gallery[initialGallery]);
    setCurrentBeforeAfter(images.beforeAfter[initialGallery]);
  }, [initialGallery]);

  return (
    <main>
      <Helmet>
        <title>Photo Gallery - {initialGallery.charAt(0).toUpperCase() + initialGallery.slice(1)} | Max Renovations</title>
        <meta
          name="description"
          content={`Browse our ${initialGallery} photo gallery and see the transformational renovation work Max Renovations has done in Glasgow. Explore before and after photos to get a sense of our craftsmanship and attention to detail.`}
        />
      </Helmet>

      {displaySlider && (
        <section className="fixed top-44 bg-neutral-800 bg-opacity-80 w-full flex justify-center pb-12 z-50">
          <div className="flex-col">
            <button className="cursor-default w-full flex justify-end py-2" onClick={closeSlider}>
              <img className="cursor-pointer h-16" src={closeIcon} alt="close icon"/>
            </button>
            <Carousel images={carouselImages} index={carouselIndex}/>
          </div>
        </section>
      )}

      <Tabs gallerySwitch={!isBeforeAndAfterView} setGallery={() => setIsBeforeAndAfterView(false)} setBeforeAndAfter={() => setIsBeforeAndAfterView(true)}/>

      <section className="bg-neutral-800 pb-12 border-t-4 border-beige">
        <StateTabs />

        {isBeforeAndAfterView ? (
          <BeforeAfterView images={currentBeforeAfter} onImageClick={openSliderWithImages} altDescription={initialGallery}/>
        ) : (
          <ImageGrid images={currentGallery} onImageClick={openSliderWithImages} altDescription={initialGallery}/>
        )
        }
      </section>
    </main>
  )
}

export default Gallery;