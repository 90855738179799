import React from "react";
import homeIcon from "../../assets/images/footer/home.svg";
import mailIcon from "../../assets/images/footer/mail.svg";
import phoneIcon from "../../assets/images/footer/phone.svg";

function ContactInformation() {
  return (
    <div className="py-12 pl-4 order-2 2xl:order-1 mx-auto 2xl:mx-0">
      <ul className="lg:flex lg:space-x-12 2xl:space-x-0 2xl:flex-col text-2xl text-darkbeige font-light">
        <li className="py-2">
          <img className="hidden sm:inline h-10 pb-1 mr-4" src={homeIcon} alt="home icon" />
          Glasgow, UK
        </li>
        <li className="py-2">
          <img className="hidden sm:inline h-10 pb-1 mr-4" src={mailIcon} alt="home icon" />
          ada.arek@wp.pl
        </li>
        <li className="py-2">
          <img className="hidden sm:inline h-10 pb-1 mr-4" src={phoneIcon} alt="home icon" />
          07377 387170
        </li>
      </ul>
    </div>
  )
}

export default ContactInformation;