import React from 'react';
import {NavLink} from "react-router-dom";

const getNavStyle = (isActive: boolean) => {
  if (isActive) {
    return "text-beige px-6 2xl:px-11 py-7 font-medium";
  }
  return "text-white hover:text-beige px-6 2xl:px-11 py-7 transition-colors duration-100 ease-in-out";
}

interface NavBarProps {
  responsiveMenu: boolean;
  scrollToTop: () => void;
  scrollToBottom: () => void;
}

const Navbar = ({ responsiveMenu, scrollToTop, scrollToBottom }: NavBarProps) => (
  <ul className={`${responsiveMenu ? "translate-x-20" : "translate-x-96"} pt-20 xl:pt-0 space-y-12 xl:space-y-0 text-xl xl:text-base h-screen xl:h-fit w-72 transition-transform bg-neutral-800 
      xl:w-fit xl:bg-transparent absolute right-0 top-0 translate-y-24 xl:translate-x-0 xl:translate-y-0 xl:static xl:flex 
      mr-16 mt-3 justify-center items-center`}>
    <li>
      <NavLink
        to="/"
        className={({ isActive }) => isActive ? "text-beige px-6 2xl:px-11 py-7 font-medium" : "text-white hover:text-beige px-6 2xl:px-11 py-7 transition-colors duration-100 ease-in-out"}
        onClick={scrollToTop}>
        HOME
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/about"
        className={({ isActive }) => getNavStyle(isActive)}
        onClick={scrollToTop}>
        ABOUT US
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/services"
        className={({ isActive }) => getNavStyle(isActive)}
        onClick={scrollToTop}>
        SERVICES
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/gallery/kitchens"
        className={({ isActive }) => getNavStyle(isActive)}
        onClick={scrollToTop}>
        GALLERY
      </NavLink>
    </li>
    <li>
      <button
        className="ml-10 py-4 border text-white hover:text-beige hover:border-beige xl:py-2 px-4 xl:ml-4 transition-colors duration-100 ease-in-out"
        onClick={scrollToBottom}>
        CONTACT US
      </button>
    </li>
  </ul>
);

export default Navbar;