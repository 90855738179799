import React from 'react';
import extensions1 from "../../assets/images/gallery/extensions/shaunWhole2.webp";
import extensions2 from "../../assets/images/gallery/extensions/doors.webp";
import extensions3 from "../../assets/images/gallery/extensions/beforeWall.webp";
import extensions4 from "../../assets/images/gallery/extensions/openDoors.webp";
import SectionComponent from "./SectionComponent";

function Extensions() {
  return (
    <SectionComponent
      images={[extensions1, extensions2, extensions3, extensions4, extensions1, extensions2]}
      titles={{
        mainTitle: "Extensions",
        subTitle: "Bespoke Home Extensions",
        sectionTitle1: "Design & Consultation",
        sectionTitle2: "Construction",
        sectionTitle3: "Completion",
        sectionTitle4: "Extensions Gallery",
      }}
      descriptions={{
        sectionDescription1: "Your home should evolve with your needs. Our specialists understand this, collaborating " +
          "with you to design an extension that blends seamlessly with your existing layout, while reflecting your " +
          "unique lifestyle and taste.",
        sectionDescription2: "We bring your vision to life with precision and craftsmanship. Whether it's an additional " +
          "room, a loft conversion, or a conservatory, we integrate your desired features with a careful eye for " +
          "detail, creating an extension that enhances your living space.",
        sectionDescription3: "Our commitment extends beyond construction. We ensure a thorough clean-up post-build and " +
          "won't consider our project complete until you're wholly satisfied with your new, customized extension. " +
          "Enjoy added space, function, and style with our tailored home extensions.",
        imageAlt: "extension"
      }}
      galleryLink="/gallery/extensions"
      seo={{
        title: "Home Extensions & Additions",
        description: "Max Renovations Builders are experts in creating functional and stylish home extensions. Learn more about our custom solutions for expanding your living space."
      }}

    />
  )
}

export default Extensions;