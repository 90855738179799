import React, { useState } from 'react';
import menuIcon from '../../assets/images/header/menu.svg';
import CallButton from './CallButton';
import useScrollPosition from '../../hooks/useScrollPosition';
import NavBar from "./NavBar";

function Header() {
  const isTop = useScrollPosition();
  const [responsiveMenu, setResponsiveMenu] = useState(false);

  const toggleResponsiveMenu = () => {
    setResponsiveMenu(prev => !prev);
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
    toggleResponsiveMenu();
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
    toggleResponsiveMenu();
  }

  return (
    <header className={ `${(isTop ? "xl:bg-transparent" : "")} bg-neutral-800 flex justify-end sm:justify-between items-center z-50 p-5 h-32 fixed top-0 left-0 w-full z-10 transition-all ease-in-out duration-500`}>
      <h2 className="font-light text-stone-50 text-3xl left-6 -translate-y-10 sm:translate-y-0 absolute sm:relative sm:text-5xl mt-2 sm:ml-8 " >Max Renovations</h2>

      <CallButton />

      <img className="xl:hidden" src={menuIcon} alt="menu icon" onClick={toggleResponsiveMenu}/>
      <NavBar responsiveMenu={responsiveMenu} scrollToTop={scrollToTop} scrollToBottom={scrollToBottom} />
    </header>
  );
}

export default Header;