import React from 'react';
import About from './components/About';
import Services from './components/Services';
import Gallery from './components/Gallery';
import Home from './components/Home';
import Root from './components/Root';
import Accessibility from "./components/Accessibility";
import Bathrooms from "./components/Services/Bathrooms";
import Kitchens from "./components/Services/Kitchens";
import Extensions from "./components/Services/Extensions";
import FullHome from "./components/Services/FullHome";
import Outdoors from "./components/Services/Outdoors";
import Roofing from "./components/Services/Roofing";
import './index.css';
import {RouterProvider, Route, createBrowserRouter, createRoutesFromElements} from 'react-router-dom';

const router = createBrowserRouter( createRoutesFromElements(
  <Route path="/" element={ <Root /> } >
    <Route path="" element={ <Home /> } />
    <Route path="about" element={ <About /> } />
    <Route path="gallery" >
      <Route index element={ <Gallery initialGallery="kitchens"/> } />
      <Route path="kitchens" element={ <Gallery initialGallery="kitchens"/> } />
      <Route path="bathrooms" element={ <Gallery initialGallery="bathrooms"/> } />
      <Route path="full-home" element={ <Gallery initialGallery="full home"/> } />
      <Route path="extensions" element={ <Gallery initialGallery="extensions"/> } />
      <Route path="outdoors" element={ <Gallery initialGallery="outdoors"/> } />
      <Route path="roofing" element={ <Gallery initialGallery="roofing"/> } />
    </Route>
    <Route path="services">
      <Route index element={ <Services /> } />
      <Route path="kitchens" element={ <Kitchens /> } />
      <Route path="bathrooms" element={ <Bathrooms /> } />
      <Route path="full-home" element={ <FullHome /> } />
      <Route path="extensions" element={ <Extensions /> } />
      <Route path="outdoors" element={ <Outdoors /> } />
      <Route path="roofing" element={ <Roofing /> } />
    </Route>
    <Route path="accessibility" element={ <Accessibility /> } />
  </Route>
))

function App() {
  return (
    <RouterProvider router={ router } />
  );
}

export default App;
