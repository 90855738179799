import React from 'react';
import kitchenImage from '../../assets/images/home/kitchen.webp';
import bathroomImage from '../../assets/images/home/bathroom.webp';
import quoteIcon from '../../assets/images/home/quote.webp';
import { Helmet } from "react-helmet";

function Home() {
  return (
    <main>
      <Helmet>
        <title>Glasgow Home Renovations | Max Renovations</title>
        <meta
          name="description"
          content="Max Renovations offer premium home renovations in Glasgow. Our team has over a decade of experience, we provide complete remodeling services including kitchen, bathroom, flooring, extensions, roofs, and more."
        />
      </Helmet>
      <div className="pt-48 sm:pt-80">
      <h1 className="text-center text-stone-50 text-4xl sm:text-7xl font-medium ">Glasgow Home Renovations</h1>
      <h2 className="text-center text-stone-50 text-2xl sm:text-4xl bg-neutral-900 bg-opacity-50 mt-9 py-3 mb-12 sm:mb-56 font-light">
        Interiors, Exteriors, We Do It All
      </h2>
      </div>
      <section className="bg-neutral-800 sm:py-12">
        <div className="sm:w-4/5 grid-cols-2 xl:grid-cols-4 grid xl:grid-rows-[40rem,40rem] mx-auto border-2 border-beige gap-0.5 bg-beige">

          <div className="hidden xl:block col-span-2 relative h-full overflow-hidden">
            <img className="absolute inset-0 object-cover w-full h-full" src={kitchenImage} alt="kitchen" />
          </div>

          <div className="py-12 col-span-2 text-neutral-50 flex flex-col justify-center items-center h-full bg-neutral-800">
            <h3 className="text-5xl text-center pr-16">Our Services</h3>
            <ul className="text-xl font-light list-disc my-8">
              <li>Complete Remodelling</li>
              <li>Kitchen</li>
              <li>Bathroom</li>
              <li>Flooring</li>
              <li>Extensions</li>
              <li>Roofs</li>
              <li>And Wide Range of Other Works</li>
            </ul>
          </div>

          <div className="py-12 col-span-2 text-neutral-50 flex flex-col justify-center items-center h-full bg-neutral-800">
            <div className="w-3/4">
              <h3 className="text-5xl font-medium">Simple & Easy</h3>
              <h4 className="text-beige text-3xl my-7">Over a Decade of Experience</h4>
              <p className="text-2xl font-light">
                For over a decade, our team has been transforming
                spaces and redefining living experiences with superior
                renovation services in Glasgow.
              </p>
              <p className="text-2xl font-light mt-5">
                Our trusted craftsmanship
                has not only etched beautiful narratives across the city,
                but has also fostered enduring relationships with our
                customers.
              </p>
            </div>
          </div>

          <div className="col-span-2 relative h-full overflow-hidden">
            <img className="absolute inset-0 object-cover w-full h-full" src={bathroomImage} alt="kitchen" />
          </div>

        </div>
      </section>

      <section className="bg-neutral-700">
        <h2 className="text-center text-beige text-4xl sm:text-5xl pt-10">TEAM TESTIMONIALS</h2>

        <div className="flex items-center justify-center my-6">
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-3 gap-16 w-3/4 mx-auto pb-12">
          <span className="bg-lightbeige pb-16 px-10 shadow-lg shadow-neutral-700">
            <img className="h-10 w-10 -translate-y-4" src={quoteIcon} alt="left quote"/>
            <p>
              Great service from Adam and his colleagues! Very prompt at attending and tracing the leak in our bathroom
              out of hours, and fixed it as agreed the next day. Great communication, can't fault them and would
              definitely use their service again. Thank you.
            </p>
          </span>
          <span className="bg-lightbeige pb-16 px-10 shadow-lg shadow-neutral-700">
            <img className="h-10 w-10 -translate-y-4" src={quoteIcon} alt="left quote"/>
            <p>
              Adam and his team were efficient, polite, and careful to keep our space clean. They did high-quality
              renovation work that I'm excited to enhance further with paint. Their attention to detail, especially in
              ensuring no dirty footprints on my carpet, was much appreciated. I'm certainly looking forward to
              inviting them back for more work.
            </p>
          </span>
          <span className="bg-lightbeige pb-16 px-10 shadow-lg shadow-neutral-700">
            <img className="h-10 w-10 -translate-y-4" src={quoteIcon} alt="left quote"/>
            <p>
              I was very pleased with the job Adam and his team did. He made contact quickly and attended when he said
              he would. I changed the specification of the job and Adam accommodated all of the variations and did a
              fantastic job. Would definitely recommend Adam.
            </p>
          </span>
        </div>
      </section>
    </main>
  );
}

export default Home;