import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

interface SectionProps {
  images: string[];
  titles: {
    mainTitle: string;
    subTitle: string;
    sectionTitle1: string;
    sectionTitle2: string;
    sectionTitle3: string;
    sectionTitle4: string;
  };
  descriptions: {
    sectionDescription1: string;
    sectionDescription2: string;
    sectionDescription3: string;
    imageAlt: string;
  };
  galleryLink: string;
  seo: {
    title: string;
    description: string;
  }
}

function SectionComponent ({images, titles, descriptions, galleryLink, seo}: SectionProps) {
  return (
    <main>
      <Helmet>
        <title>{seo.title} | Max Renovations</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <h1 className="text-stone-50 text-5xl md:text-7xl pt-48 font-medium text-center">{titles.mainTitle}</h1>
      <h3 className="text-center text-stone-50 text-2xl md:text-4xl bg-neutral-900 bg-opacity-50 py-3 font-light my-12">{titles.subTitle}</h3>

      <section className="bg-neutral-800">
        <div className="grid grid-cols-1 xl:grid-cols-2 border-beige border-y-2 gap-0.5 bg-beige">
          <div className="order-2 xl:order-1">
            <img className="w-full" src={images[0]} alt={descriptions.imageAlt} />
          </div>
          <div className="order-1 xl:order-2 py-12 flex flex-col items-center justify-center bg-neutral-800">
            <h2 className="text-4xl font-medium text-center text-stone-50 mb-12">{titles.sectionTitle1}</h2>
            <p className="text-xl text-center text-stone-50 font-light leading-9 w-3/5 mx-auto">
              {descriptions.sectionDescription1}
            </p>
          </div>

          <div className="order-3 xl:order-3 py-12 flex flex-col items-center justify-center bg-neutral-800">
            <h2 className="text-4xl font-medium text-center text-stone-50 mb-12">{titles.sectionTitle2}</h2>
            <p className="text-xl text-center text-stone-50 font-light leading-9 w-3/5 mx-auto">
              {descriptions.sectionDescription2}
            </p>
          </div>
          <div className="order-4 xl:order-4">
            <img className="w-full"  src={images[1]} alt={descriptions.imageAlt} />
          </div>

          <div className="order-6 xl:order-5">
            <img className="w-full"  src={images[2]} alt={descriptions.imageAlt} />
          </div>
          <div className="order-5 xl:order-6 py-12 flex flex-col items-center justify-center bg-neutral-800">
            <h2 className="text-4xl font-medium text-center text-stone-50 mb-12">{titles.sectionTitle3}</h2>
            <p className="text-xl text-center text-stone-50 font-light leading-9 w-3/5 mx-auto">
              {descriptions.sectionDescription3}
            </p>
          </div>
        </div>
      </section>
      <section className="bg-neutral-800">
        <h2 className="text-center text-6xl font-medium text-stone-50 mb-8 pt-12">{titles.sectionTitle4}</h2>
        <hr className="w-4/5 mx-auto border-0 bg-beige h-0.5 mb-8"/>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 w-4/5 mx-auto">
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={images[0]} alt={descriptions.imageAlt} />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={images[1]} alt={descriptions.imageAlt} />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={images[2]} alt={descriptions.imageAlt} />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={images[3]} alt={descriptions.imageAlt} />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={images[4]} alt={descriptions.imageAlt} />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={images[5]} alt={descriptions.imageAlt} />
          </div>
        </div>
        <div className="bg-gradient-to-t from-neutral-800 -translate-y-64 to-transparent h-64 flex justify-center items-end">
          <Link to={galleryLink} onClick={() => {window.scrollTo({top: 0,})}}>
            <button className="px-12 md:px-32 py-4 translate-y-28 text-neutral-900 bg-beige hover:bg-darkbeige active:text-white transition-colors" type="button">SEE MORE</button>
          </Link>
        </div>
      </section>
    </main>
  )
}

export default SectionComponent;