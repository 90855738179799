import React from 'react';
import { Link } from "react-router-dom";
import kitchenImage from "../../assets/images/services/kitchen.webp";
import bathroomImage from "../../assets/images/services/bathroom.webp";
import fullhomeImage from "../../assets/images/services/fullhome.webp";
import extensionImage from "../../assets/images/services/extension.webp";
import outdoorsImage from "../../assets/images/services/outdoors.webp";
import roofImage from "../../assets/images/services/roof.webp";
import { Helmet } from "react-helmet";

function Services() {

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
    });
  }

  return (
    <main>
      <Helmet>
        <title>Services | Max Renovations</title>
        <meta name="description" content="Max Renovations offers a range of home renovation services, including kitchens, bathrooms, full home renovations, extensions, outdoors & landscapes, and roofing." />
      </Helmet>
      <h1 className="pt-48 text-5xl text-center text-stone-50 lg:text-7xl lg:pt-60 font-medium lg:text-left lg:pl-64">Services</h1>
      <h2 className="text-center mb-20 text-2xl text-stone-50 lg:text-4xl mt-12 lg:mb-32 font-light lg:text-left bg-neutral-900 bg-opacity-50 py-3 lg:pl-64">
        Transforming Spaces, Exceeding Expectations: Your Vision, Our Expertise
      </h2>

      <section className="bg-neutral-800 md:py-16">
        <div className="grid md:grid-cols-2 xl:grid-cols-3 md:w-4/5 mx-auto gap-x-20 gap-y-8">

          <Link to="kitchens" className="z-30" onClick={scrollTop}>
            <div className="border-2 border-beige z-20 hover:-translate-y-2 hover:shadow-neutral-950 hover:shadow-2xl transition-all h-full">

              <div className="relative custom-image z-30 ">
                <img src={kitchenImage} alt="kitchen"/>
                <div className="absolute top-0 left-0 bg-stone-50 h-full w-full"></div>
                <button className="absolute top-0 left-0 h-full w-full text-5xl font-light transition-all">LEARN MORE</button>
              </div>

              <div className="border-t-2 border-beige">
                <h2 className="text-center text-3xl my-4 text-stone-50 font-medium">Kitchens</h2>
                <p className="px-6 pb-8 pt-2 text-stone-100">
                  Our team is dedicated to crafting your ideal kitchen, through renovation, remodeling, or new construction.
                  We handle all electronic and hydraulic details, delivering top-tier results, so you can enjoy a worry-free experience.
                </p>
              </div>

            </div>
          </Link>

          <Link to="bathrooms" className="z-30" onClick={scrollTop}>
            <div className="border-2 border-beige z-20 hover:-translate-y-2 hover:shadow-neutral-950 hover:shadow-2xl transition-all h-full">

              <div className="relative custom-image z-30">
                <img src={bathroomImage} alt="kitchen"/>
                <div className="absolute top-0 left-0 bg-stone-50 h-full w-full"></div>
                <button className="absolute top-0 left-0 h-full w-full text-5xl font-light transition-all">LEARN MORE</button>
              </div>

              <div className="border-t-2 border-beige">
                <h2 className="text-center text-3xl my-4 text-stone-50 font-medium">Bathrooms</h2>
                <p className="px-6 pb-8 pt-2 text-stone-100">
                  Experience the transformation of your bathroom into a spa-like retreat with our meticulous attention to detail. From intricate plumbing to elegant tilework, we remove any renovation stress, ensuring only superior outcomes.
                </p>
              </div>

            </div>
          </Link>

          <Link to="full-home" className="z-30" onClick={scrollTop}>
            <div className="border-2 border-beige z-20 hover:-translate-y-2 hover:shadow-neutral-950 hover:shadow-2xl transition-all h-full">
              <div className="relative custom-image z-30">
                <img src={fullhomeImage} alt="kitchen"/>
                <div className="absolute top-0 left-0 bg-stone-50 h-full w-full"></div>
                <button className="absolute top-0 left-0 h-full w-full text-5xl font-light transition-all">LEARN MORE</button>
              </div>
              <div className="border-t-2 border-beige">
                <h2 className="text-center text-3xl my-4 text-stone-50 font-medium">Full Home</h2>
                <p className="px-6 pb-8 pt-2 text-stone-100">
                  Transform your living space into a stunning haven with our home renovation experts. We remodel and revamp everything, from kitchens to bedrooms, ensuring you a beautiful, hassle-free home renovation experience.                </p>
              </div>
            </div>
          </Link>

          <Link to="extensions" className="z-30" onClick={scrollTop}>
            <div className="border-2 border-beige z-20 hover:-translate-y-2 hover:shadow-neutral-950 hover:shadow-2xl transition-all h-full">
              <div className="relative custom-image z-30">
                <img src={extensionImage} alt="kitchen"/>
                <div className="absolute top-0 left-0 bg-stone-50 h-full w-full"></div>
                <button className="absolute top-0 left-0 h-full w-full text-5xl font-light transition-all">LEARN MORE</button>
              </div>
              <div className="border-t-2 border-beige">
                <h2 className="text-center text-3xl my-4 text-stone-50 font-medium">Extensions</h2>
                <p className="px-6 pb-8 pt-2 text-stone-100">
                  Seamlessly expand your living area with our skilled craftspeople. Handling every aspect of the extension project, from foundations to finishes, we promise superior outcomes and a trouble-free expansion journey.
                </p>
              </div>
            </div>
          </Link>

          <Link to="outdoors" className="z-30" onClick={scrollTop}>
            <div className="border-2 border-beige z-20 hover:-translate-y-2 hover:shadow-neutral-950 hover:shadow-2xl transition-all h-full">
              <div className="relative custom-image z-30">
                <img src={outdoorsImage} alt="kitchen"/>
                <div className="absolute top-0 left-0 bg-stone-50 h-full w-full"></div>
                <button className="absolute top-0 left-0 h-full w-full text-5xl font-light transition-all">LEARN MORE</button>
              </div>
              <div className="border-t-2 border-beige">
                <h2 className="text-center text-3xl my-4 text-stone-50 font-medium">Outdoors & Landscapes</h2>
                <p className="px-6 pb-8 pt-2 text-stone-100">
                  Watch your outdoor space bloom into a serene oasis with our landscape professionals. We sculpt and shape everything, from patios to flowerbeds, providing you with a beautiful, stress-free landscape experience.
                </p>
              </div>
            </div>
          </Link>

          <Link to="roofing" className="z-30" onClick={scrollTop}>
            <div className="border-2 border-beige z-20 hover:-translate-y-2 hover:shadow-neutral-950 hover:shadow-2xl transition-all h-full">
              <div className="relative custom-image z-30">
                <img src={roofImage} alt="kitchen"/>
                <div className="absolute top-0 left-0 bg-stone-50 h-full w-full"></div>
                <button className="absolute top-0 left-0 h-full w-full text-5xl font-light transition-all">LEARN MORE</button>
              </div>
              <div className="border-t-2 border-beige">
                <h2 className="text-center text-3xl my-4 text-stone-50 font-medium">Roofing</h2>
                <p className="px-6 pb-8 pt-2 text-stone-100">
                  Safe and stylish roofs are our specialty. Our roofing experts handle all elements from shingles to gutters, ensuring your home's protection while delivering exceptional, worry-free results.
                </p>
              </div>
            </div>
          </Link>
        </div>
      </section>
    </main>
  )
}

export default Services;