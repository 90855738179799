import React from 'react';
import roofing1 from "../../assets/images/gallery/roofing/DSC02024.webp";
import roofing2 from "../../assets/images/gallery/roofing/DSC02026.webp";
import roofing3 from "../../assets/images/gallery/roofing/DSC02025.webp";
import roofing4 from "../../assets/images/gallery/roofing/DSC02029.webp";
import roofing5 from "../../assets/images/gallery/roofing/DSC02033.webp";
import roofing6 from "../../assets/images/gallery/roofing/DSC02034.webp";
import SectionComponent from "./SectionComponent";

function Roofing() {
  return (
    <SectionComponent
      images={[roofing1, roofing2, roofing3, roofing4, roofing5, roofing6]}
      titles={{
        mainTitle: "Roofing",
        subTitle: "Redefining Skylines",
        sectionTitle1: "Consultation & Design",
        sectionTitle2: "Installation & Quality Assurance",
        sectionTitle3: "Project Completion",
        sectionTitle4: "Roofing Gallery",
      }}
      descriptions={{
        sectionDescription1: "Your roof is a crucial element of your home, influencing both its aesthetics and " +
          "safety. Our roofing experts work with you to understand your needs, suggesting designs and materials " +
          "that enhance your home's appearance while ensuring long-lasting protection.",
        sectionDescription2: "From modern flat roofs to traditional pitched styles, we meticulously install your " +
          "chosen roofing with precision. Our team ensures that every shingle, tile, or panel contributes to a " +
          "secure and stylish roof that stands the test of time.",
        sectionDescription3: "Our commitment extends beyond the installation process. We provide a thorough " +
          "clean-up after the project, leaving your home neat and your new roof ready to weather any storm. Our " +
          "work isn't done until you're fully satisfied with your secure, stylish rooftop.",
        imageAlt: "roof"
      }}
      galleryLink="/gallery/roofing"
      seo={{
        title: "Roofing Services",
        description: "Max Renovations provides top-quality roofing services, including consultation, design, and installation. Check out our roofing gallery to see our past projects"
      }}
    />
  )
}

export default Roofing;