import React from 'react';
import { Link } from 'react-router-dom';
import CallUsButton from "./CallUsButton";
import CustomerForm from "./CustomerForm";
import ContactInformation from "./ContactInformation";

function Footer() {
  return (
    <footer className="bg-neutral-800">
      <h3 className="text-5xl text-darkbeige ml-4 pt-4">Contact Us</h3>
      <section className="flex flex-col 2xl:flex-row 2xl:flex-wrap justify-between">

        <ContactInformation />

        <div className="flex flex-col lg:flex-row mx-auto w-3/4 2xl:w-1/2 justify-around order-1 2xl:order-2">
          <CallUsButton />
          <h4 className="text-center text-darkbeige text-3xl mt-32 mx-4 -translate-y-14 lg:-translate-y-0">OR</h4>
          <CustomerForm />
        </div>

        <div className="flex order-3 2xl:order-3 mx-auto">
          <ul className="hidden lg:flex 2xl:flex-col text-darkbeige space-x-2 2xl:space-x-0 2xl:mt-12">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
            <li><Link to="/accessibility">Accessibility Statement</Link></li>
          </ul>
        </div>

      </section>
      <h4 className="text-darkbeige font-light ml-6 pb-4"> @2023 by <a href="https://github.com/maxjski" className="underline hover:no-underline" target="_blank" rel="noreferrer">Maksymilian Jankowski</a> </h4>
    </footer>
  );
}

export default Footer;