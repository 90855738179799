import React, {FormEvent, useState} from "react";
import {useForm} from "@formspree/react";

const FORMSPREE_FORM_ID = 'mbjvjvvb';
const THANK_YOU_MESSAGE_DURATION = 5000;

interface IFormInput {
  name: string;
  email: string;
  phone: string;
  address: string;
}

function CustomerForm() {
  const [formInput, setFormInput] = useState<IFormInput>({
    name: '',
    email: '',
    phone: '',
    address: ''
  });
  const [thanksVisibility, setThanksVisibility] = useState(false);
  const [, handleSubmit ] = useForm(FORMSPREE_FORM_ID);

  const handleSubmitWrapper = async (e: FormEvent) => {
    e.preventDefault();
    await handleSubmit(e);
    setFormInput({
      name: '',
      email: '',
      phone: '',
      address: ''
    });

    setThanksVisibility(true);
    setTimeout(() => {
      setThanksVisibility(false);
    }, THANK_YOU_MESSAGE_DURATION);
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value
    });
  }

  return (
    <div className="mt-6">
      <h2 className="text-center text-stone-100 text-4xl font-bold mb-6">Let Us Call You</h2>
      <form onSubmit={handleSubmitWrapper} name="Contact Form">
        <div className="grid grid-cols-2 gap-3">
          <div>
            <label htmlFor="name" className="block mb-2 font-medium text-beige dark:text-gray-300">
              Full Name
            </label>
            <input
              className="outline-none focus:border-b-4 focus:pb-1.5 bg-transparent border-b-2 border-beige text-beige pb-2 w-full"
              name="name"
              type="text"
              id="name"
              value={formInput.name}
              onChange={handleInputChange}/>
          </div>

          <div>
            <label htmlFor="email" className="block mb-2 font-medium text-beige dark:text-gray-300">
              Email *
            </label>
            <input
              className="outline-none focus:border-b-4 focus:pb-1.5 bg-transparent border-b-2 border-beige text-beige pb-2 w-full"
              name="email"
              type="email"
              id="email"
              value={formInput.email}
              onChange={handleInputChange}
              required />
          </div>

          <div>
            <label htmlFor="phone" className="block mb-2 font-medium text-beige dark:text-gray-300">
              Phone
            </label>
            <input
              className="outline-none focus:border-b-4 focus:pb-1.5 bg-transparent border-b-2 border-beige text-beige pb-2 w-full"
              name="phone"
              type="tel"
              id="phone"
              value={formInput.phone}
              onChange={handleInputChange}/>
          </div>

          <div>
            <label htmlFor="postal-code" className="block mb-2 font-medium text-beige dark:text-gray-300">
              Postal Code
            </label>
            <input
              className="outline-none focus:border-b-4 focus:pb-1.5 bg-transparent border-b-2 border-beige text-beige pb-2 w-full"
              name="address"
              type="text"
              id="postal-code"
              value={formInput.address}
              onChange={handleInputChange}/>
          </div>
        </div>
        <button className="px-20 py-2 mt-12 text-beige border-2 border-beige w-full hover:bg-beige hover:text-stone-800 active:text-white transition-all" value="Submit" >Submit</button>
        <h3 className={`${thanksVisibility ? "" : "opacity-0"} text-center text-2xl font-light mt-2 text-darkbeige transition-all`}>Thanks for submitting!</h3>
      </form>
    </div>
  );
}

export default CustomerForm;