import React from 'react';

interface TabsProps {
  setGallery: () => void;
  setBeforeAndAfter: () => void;
  gallerySwitch: boolean;
}

function Tabs({setGallery, setBeforeAndAfter, gallerySwitch}: TabsProps) {

  return gallerySwitch ? (
    <div className="flex justify-center pt-72 sm:space-x-6 md:space-x-12">
      <button
        className="text-white text-3xl md:text-5xl lg:text-7xl bg-neutral-800 px-8 py-4 translate-y-1 border-l-4 border-t-4 sm:border-r-4 border-beige"
        onClick={setGallery}>
        Gallery
      </button>
      <button
        className="text-neutral-500 text-3xl md:text-5xl lg:text-7xl bg-neutral-900 px-8 py-4 translate-y-1 border-l-4 border-t-4 border-r-4 border-b-4 border-beige"
        onClick={setBeforeAndAfter}>
        Before & After
      </button>
    </div>
  ) : (
    <div className="flex justify-center pt-72 sm:space-x-6 md:space-x-12">
      <button
        className="text-neutral-500 text-3xl md:text-5xl lg:text-7xl bg-neutral-900 px-8 py-4 translate-y-1 border-l-4 border-t-4 sm:border-r-4 border-b-4 border-beige"
        onClick={setGallery}>
        Gallery
      </button>
      <button
        className="text-white text-3xl md:text-5xl lg:text-7xl bg-neutral-800 px-8 py-4 translate-y-1 border-l-4 border-t-4 border-r-4 border-beige"
        onClick={setBeforeAndAfter}>
        Before & After
      </button>
    </div>
  );
}

export default Tabs;