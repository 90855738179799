import React from 'react';
import fullhome1 from "../../assets/images/gallery/fullhome/interior2img25.webp";
import fullhome2 from "../../assets/images/gallery/fullhome/rita2Bed6.webp";
import fullhome3 from "../../assets/images/gallery/fullhome/interior2img11.webp";
import fullhome4 from "../../assets/images/gallery/fullhome/interior2img15.webp";
import fullhome5 from "../../assets/images/gallery/fullhome/interiorBeforeStairs.webp";
import fullhome6 from "../../assets/images/gallery/fullhome/ritaBed.webp";
import SectionComponent from "./SectionComponent";

function FullHome() {
  return (
    <SectionComponent
      images={[fullhome1, fullhome2, fullhome3, fullhome4, fullhome5, fullhome6]}
      titles={{
        mainTitle: "Full Home",
        subTitle: "Comprehensive Home Makeovers",
        sectionTitle1: "Concept & Planning",
        sectionTitle2: "Renovation",
        sectionTitle3: "Completion",
        sectionTitle4: "Full Home Gallery",
      }}
      descriptions={{
        sectionDescription1: "Revitalizing your entire home should be a reflection of your lifestyle and aesthetics. " +
          "We collaborate with you to conceptualize a renovation plan that revitalizes your home while seamlessly " +
          "blending with your unique taste.",
        sectionDescription2: "Transform your vision into reality with our expert craftsmanship. Be it a chic kitchen, " +
          "a spa-like bathroom, a cozy living room, or a functional home office, we meticulously incorporate your " +
          "desired features to create a home that's truly yours.",
        sectionDescription3: "Our commitment doesn't end at the final build. We provide thorough post-renovation " +
          "clean-up, and our job isn't done until you're completely satisfied. Rediscover the joy of a home that's not " +
          "just new, but a true reflection of you.",
        imageAlt: "home interior"
      }}
      galleryLink="/gallery/full-home"
      seo={{
        title: "Full Home Renovations",
        description: "At Max Renovations, we provide comprehensive home renovation services. From the foundation to the roof, we rejuvenate every corner of your home."
      }}
    />
  )
}

export default FullHome;