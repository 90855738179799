import React from 'react';

interface ImageGridProps {
  images: string[];
  onImageClick: (images: string[], index: number) => void;
  altDescription: string;
}

function ImageGrid({images, onImageClick, altDescription}: ImageGridProps) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 w-4/5 mx-auto mt-12">
      {images.map((image, index) => (
        <div key={index} onClick={() => onImageClick(images,index)}>
          <img className="w-auto h-full border-2 border-beige cursor-pointer" src={image} alt={altDescription}/>
        </div>
      ))}
    </div>
  );
}

export default ImageGrid;