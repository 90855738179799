import React from 'react';
import interiorImage from "../../assets/images/about/interior2img25.webp";
import windowImage from "../../assets/images/about/greenWindow.webp";
import workImage from "../../assets/images/about/projectBeforeWork.webp";
import kitchenImage from "../../assets/images/about/shaunWhole2.webp";
import bedroomImage from "../../assets/images/about/rita2Bed6.webp";
import wallImage from "../../assets/images/about/ritaWall.webp";
import sinkImage from "../../assets/images/about/whiteSink.webp";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

function About() {
  return (
    <main>
      <Helmet>
        <title>Glasgow Home Renovations | Max Renovations</title>
        <meta
          name="description"
          content="Max Renovations has been renovating spaces in Glasgow for almost two decades."
        />
      </Helmet>
      <h1 className="pt-48 text-5xl text-center text-stone-50 lg:text-7xl lg:pt-60 font-medium lg:text-left lg:pl-64">About Us</h1>
      <h2 className="text-center mb-20 text-2xl text-stone-50 lg:text-4xl mt-12 lg:mb-32 font-light lg:text-left bg-neutral-900 bg-opacity-50 py-3 lg:pl-64">
        Delivering Quality and Craftsmanship in Home Renovations since 2007
      </h2>

      <section className="bg-neutral-800 md:py-12">
        <div className="grid lg:grid-cols-2 lg:grid-rows-[40rem] md:mx-auto md:w-4/5 border-2 border-beige gap-0.5 bg-beige">


          <div className="py-12 text-neutral-50 flex flex-col justify-center items-center h-full bg-neutral-800">
            <div className="w-3/4">
              <h3 className="text-5xl font-medium">Our Journey</h3>
              <h4 className="text-beige text-3xl my-7">Almost Two Decades of <br />Value-Driven Excellence</h4>
              <p className="text-xl font-light">
                Our journey began with a simple vision: from minor repairs to constructing dream homes, we make quality
                renovation and remodeling accessible to everyone, regardless of budget. Over the past decade, we've
                stayed true to this vision, continuously improving and delivering superior service at competitive
                prices.
              </p>
              <p className="text-xl font-light mt-5">
                We promise not just to meet, but to exceed your expectations with tailored solutions that
                align with your financial considerations.
              </p>
            </div>
          </div>

          <div className="relative h-full overflow-hidden">
            <img className="absolute inset-0 object-cover w-full h-full" src={bedroomImage} alt="kitchen" />
          </div>
        </div>

        <h2 className="text-beige text-center text-6xl md:text-8xl font-light mt-16 mb-20">16 Years of Experience</h2>

        <div className="grid xl:grid-cols-4 md:mx-auto md:w-4/5 border-2 border-darkbeige gap-0.5 bg-darkbeige">
          <div className="flex flex-col justify-center">
            <h3 className="py-12 text-stone-50 text-4xl font-medium inline-block mx-auto">Our <br /> Competitive Edge</h3>
          </div>

          <div className="bg-neutral-800 flex flex-col px-10 pt-10">
            <h4 className="text-3xl text-beige mb-6">Exceptional <br /> Expertise</h4>
            <p className="text-xl pb-12 text-stone-50 font-light">
              We're committed to upholding the highest standards of work in every project we undertake. Our team of
              seasoned professionals ensures each task aligns perfectly with your unique specifications and vision.
            </p>
          </div>

          <div className="bg-neutral-800 flex flex-col px-10 pt-10">
            <h4 className="text-3xl text-beige mb-6">Uncompromising Safety</h4>
            <p className="text-xl pb-12 text-stone-50 font-light">
              Safety is our utmost priority. We've fostered a work environment that not only prioritizes health and
              safety but also rigorously adheres to industry best practices to ensure a secure project space.
            </p>
          </div>

          <div className="bg-neutral-800 flex flex-col px-10 pt-10">
            <h4 className="text-3xl text-beige mb-6">Trustworthy Track Record</h4>
            <p className="text-xl pb-12 text-stone-50 font-light">
              Over a remarkable 16-year journey, we've established a reputation of reliability and trust with our
              clients and within our city. We invite you to become part of our growing community, confident in our
              unwavering commitment to your satisfaction.
            </p>
          </div>
        </div>
      </section>
      <section className="mt-64 bg-neutral-800 md:pb-20">
        <h2 className="text-center text-stone-50 text-7xl -translate-y-12 font-medium">Explore Gallery</h2>
        <hr className="w-2/5 mx-auto h-2 bg-stone-50 -translate-y-6"/>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 w-4/5 mx-auto">
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={sinkImage} alt="outdoors" />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={wallImage} alt="kitchen" />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={workImage} alt="kitchen" />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={kitchenImage} alt="kitchen" />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={windowImage} alt="kitchen" />
          </div>
          <div>
            <img className="w-auto h-full border-2 border-beige"
                 src={interiorImage} alt="kitchen" />
          </div>
        </div>
        <div className="bg-gradient-to-t from-neutral-800 -translate-y-64 to-transparent h-64 flex justify-center items-end">
          <Link to="/gallery/kitchens" onClick={() => {window.scrollTo({top: 0,})}}>
            <button className="px-16 md:px-32 py-4 translate-y-28 text-neutral-900 bg-beige hover:bg-darkbeige active:text-white transition-colors" type="button">SEE GALLERY</button>
          </Link>
        </div>
      </section>
    </main>
  );
}

export default About;