import React from 'react';
import bathroom1 from "../../assets/images/gallery/bathrooms/grayWhole.webp";
import bathroom2 from "../../assets/images/gallery/bathrooms/smallBeforeWhole2.webp";
import bathroom3 from "../../assets/images/gallery/bathrooms/rita3Bath.webp";
import bathroom4 from "../../assets/images/gallery/bathrooms/rita2Whole.webp";
import bathroom5 from "../../assets/images/gallery/bathrooms/whiteWhole2.webp";
import bathroom6 from "../../assets/images/gallery/bathrooms/smallBath5.webp";
import SectionComponent from "./SectionComponent";

function Bathrooms() {
  return (
    <SectionComponent
      images={[bathroom1, bathroom2, bathroom3, bathroom4, bathroom5, bathroom6]}
      titles={{
        mainTitle: "Bathrooms",
        subTitle: "Where Excellence Meets Functionality",
        sectionTitle1: "Planning",
        sectionTitle2: "Execution",
        sectionTitle3: "Finish",
        sectionTitle4: "Bathrooms Gallery",
      }}
      descriptions={{
        sectionDescription1: "Our expert team excels in blending style and function for transformative bathroom " +
          "designs. We navigate your journey from concept to completion, aligning every detail with your unique needs. " +
          "With us, your bathroom isn't just renovated, it's reimagined.",
        sectionDescription2: "From installing cutting-edge LED lighting to fitting luxurious jacuzzis and efficient " +
          "underfloor heating, we bring your dream bathroom to life. With meticulous attention to detail, we incorporate " +
          "your desired features, transforming your bathroom into a haven of comfort.",
        sectionDescription3: "Our dedication extends beyond the build. We provide professional clean-up after the " +
          "renovation and don't consider our job done until you're 100% satisfied with your new, dream-come-true bathroom.",
        imageAlt: "bathroom"
      }}
      galleryLink="/gallery/bathrooms"
      seo={{
        title: "Bathroom Remodeling & Renovation",
        description: "Upgrade your bathroom with Max Renovations' premium remodeling and renovation services. See how we blend style and function to revitalize your bathroom."
      }}
    />
  )
}

export default Bathrooms;