import React from 'react';
import useWindowSize from "../../hooks/useWindowSize";

const CallButton: React.FC = () => {
  const handleOnClick = () => {
    window.open(`tel:+447377387170}`, '_self');
  }

  const size = useWindowSize();

  return size.width && size.width >= 1280 ? (
    <div className="relative top-2 2xl:pr-10 translate-x-4">
      <span className="text-white block text-2xl 2xl:text-3xl font-medium">CALL US NOW</span>
      <span className="text-white lg:text-2xl 2xl:text-2xl pl-14">07377 387170</span>
    </div>
  ) : (
    <button className="mr-12 translate-y-2 sm:translate-y-0 relative top-2 2xl:pr-10 border px-2 sm:px-4 py-2 border-2" onClick={handleOnClick}>
      <span className="text-white text-xl sm:text-2xl">CALL US NOW</span>
    </button>
  );
}

export default CallButton;
