import React from 'react';
import {Helmet} from "react-helmet";

function Accessibility() {
  return (
    <main>
      <Helmet>
        <title>Accessibility Statement | Max Renovations</title>
        <meta
          name="description"
          content="We are committed to ensuring digital accessibility for people with disabilities. We continually improve the user experience for everyone and apply the relevant accessibility standards."
        />
      </Helmet>
      <section className="bg-neutral-800 py-16">
        <div className="w-3/5 mx-auto text-stone-50 space-y-6 text-2xl font-light">
          <h1 className=" text-5xl text-beige mt-24">Accessibility Statement</h1>
          <p>This is an accessibility statement from Max Renovations.</p>

          <h2 className=" text-5xl text-beige mt-24">Measures to support accessibility</h2>
          <p>Max Renovations takes the following measures to ensure accessibility of Max Renovations</p>
          <ul>
            <li>- Assign clear accessibility goals and responsibilities.</li>
            <li>- Provide continual accessibility training for our staff.</li>
          </ul>

          <h2 className=" text-5xl text-beige mt-24">Conformance status</h2>
          <p>
            The Web Content Accessibility Guidelines (WCAG) defines requirements for designers and developers to
            improve accessibility for people with disabilities. It defines three levels of conformance: Level A,
            Level AA, and Level AAA. Max Renovations is partially conformant with WCAG 2.1 level AA. Partially
            conformant means that some parts of the content do not fully conform to the accessibility standard.
          </p>

          <h2 className=" text-5xl text-beige mt-24">Additional accessibility considerations</h2>
          <p>
            Images of text are only used for decorative purposes. Images have text descriptions. High contrast.
            Linear and easy to follow design.
          </p>

          <h2 className=" text-5xl text-beige mt-24">Feedback</h2>
          <p>
            We welcome your feedback on the accessibility of Max Renovations. Please let us know if you encounter
            accessibility barriers on Max Renovations:
          </p>
          <ul>
            <li>- E-mail: ma.ad.jank@gmail.com</li>
            <li>- E-mail: ada.arek@wp.pl</li>
          </ul>

          <h2 className=" text-5xl text-beige mt-24">Date</h2>
          <p>This statement was created on 10 August 2021 using the W3C Accessibility Statement Generator Tool.</p>
        </div>
      </section>
    </main>
  )
}

export default Accessibility;