import React from 'react';
import outdoors1 from "../../assets/images/gallery/outdoors/projectBeforeWhole.webp";
import outdoors2 from "../../assets/images/gallery/outdoors/projectGrass3.webp";
import outdoors3 from "../../assets/images/gallery/outdoors/playground.webp";
import outdoors4 from "../../assets/images/gallery/outdoors/projectBeforeWork2.webp";
import outdoors5 from "../../assets/images/gallery/outdoors/shaunGrass2.webp";
import outdoors6 from "../../assets/images/gallery/outdoors/shaunSlide2.webp";
import SectionComponent from "./SectionComponent";

function Outdoors() {
  return (
    <SectionComponent
      images={[outdoors1, outdoors2, outdoors3, outdoors4, outdoors5, outdoors6]}
      titles={{
        mainTitle: "Outdoor & Landscapes",
        subTitle: "Creating Harmony Between Home and Nature",
        sectionTitle1: "Design & Planning",
        sectionTitle2: "Installation & Refinement",
        sectionTitle3: "Completion",
        sectionTitle4: "Outdoors & Landscapes Gallery",
      }}
      descriptions={{
        sectionDescription1: "Your outdoor space, the soul of your home, should cater to your relaxation needs and " +
          "design sensibilities. Our landscape architects work closely with you to formulate a detailed blueprint, " +
          "modifying every aspect to shape your ideal outdoor environment.",
        sectionDescription2: "From concept to reality, we bring your vision to life. Our experienced landscaping team " +
          "carefully manages each step of the transformation. Adapting to your unique requirements, we craft an outdoor " +
          "haven that's a harmonious blend of function and style, reflecting your desires and complementing your home.",
        sectionDescription3: "Our commitment extends beyond the implementation stage. We provide meticulous clean-up " +
          "post-remodeling, ensuring your new outdoor space is ready for you to enjoy and embrace nature. We consider " +
          "our job complete only when you're fully satisfied with your personalized, outdoor landscape transformation.",
        imageAlt: "outdoors"
      }}
      galleryLink="/gallery/outdoors"
      seo={{
        title: "Outdoor Construction & Landscaping",
        description: "Max Renovations specializes in outdoor construction and landscaping projects. Explore our portfolio to see our outstanding work on outdoor living spaces."
      }}
    />
  )
}

export default Outdoors;