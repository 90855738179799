import React, {useState} from 'react';
import leftArrow from '../../assets/images/gallery/icons/arrow-left.svg';
import rightArrow from '../../assets/images/gallery/icons/arrow-right.svg';

interface CarouselProps {
  images: string[];
  index: number;
}

function Carousel({images, index}: CarouselProps) {
  const [displayImageIndex, setDisplayImageIndex] = useState(index);

  const swipeRight = () => {
    if(displayImageIndex<images.length-1) {
      setDisplayImageIndex(prev => prev + 1);
    }
  }

  const swipeLeft = () => {
    if (displayImageIndex > 0) {
      setDisplayImageIndex(prev => prev-1);
    }
  }

  return (
    <div className="h-[13rem] sm:h-[25rem] md:h-[30rem] lg:h-[40rem] relative">
      <img className="h-full w-full border-2 border-beige" src={images[displayImageIndex]} alt="..." />
      <div className="absolute left-0 right-0 top-0 bottom-0 flex justify-between items-center z-30">
        <button className="h-full w-1/2 flex justify-start items-center" onClick={swipeLeft}>
          <img className="bg-neutral-800 bg-opacity-70 opacity-80 h-10 sm:h-20 rounded-full mx-3" src={leftArrow} alt="left arrow" />
        </button>
        <button className="h-full w-1/2 flex justify-end items-center" onClick={swipeRight}>
          <img className="bg-neutral-800 bg-opacity-70 opacity-80 h-10 sm:h-20 rounded-full mx-3" src={rightArrow} alt="right arrow" />
        </button>
      </div>
    </div>
  );
}

export default Carousel;