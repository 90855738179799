import React from 'react';

interface BeforeAfterViewProps {
  images: string[];
  onImageClick: (images: string[], index: number) => void;
  altDescription: string;
}

function BeforeAfterView({images, onImageClick, altDescription}: BeforeAfterViewProps) {
  return (
    <div className="grid grid-cols-2 w-11/12 lg:w-4/6 mx-auto gap-y-6 gap-x-2 lg:gap-8 border-t-2 lg:border-0 border-beige mt-6 pt-4 lg:mt-12">
      <h4 className="text-center text-3xl sm:text-5xl text-neutral-50 font-medium">Before</h4>
      <h4 className="text-center text-3xl sm:text-5xl text-neutral-50 font-medium">After</h4>
      {images.map((image, index) => (
        <div key={index} onClick={() => onImageClick(images,index)}>
          <img className="w-auto h-full border-2 border-beige cursor-pointer" src={image.toString()} alt={altDescription}/>
        </div>
      ))}
    </div>
  );
}

export default BeforeAfterView;