import React from 'react';

function CallUsButton() {

  const handleOnClick = () => {
    window.open(`tel:+447377387170}`, '_self');
  }

  return (
    <div className="flex-col h-full mx-auto lg:mx-0">
      <button className="border border-4 px-4 py-8 mt-10 hover:bg-neutral-700 transition-all" onClick={handleOnClick}>
        <h2 className="text-center text-stone-100 text-5xl font-bold mb-6">Call Us Now</h2>
        <h3 className="text-center text-white text-4xl">+44 7737 387170</h3>
      </button>
    </div>
  )
}

export default CallUsButton;