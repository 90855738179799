import React from 'react';
import kitchen1 from "../../assets/images/gallery/kitchens/shaunWhole.webp";
import kitchen2 from "../../assets/images/gallery/kitchens/ritaSink.webp";
import kitchen3 from "../../assets/images/gallery/kitchens/shaunWhole3.webp";
import kitchen4 from "../../assets/images/gallery/kitchens/ritaTabletop.webp";
import kitchen5 from "../../assets/images/gallery/kitchens/ritaWhole5.webp";
import kitchen6 from "../../assets/images/gallery/kitchens/shaunSink.webp";
import SectionComponent from "./SectionComponent";

function Kitchens() {
  return (
    <SectionComponent
      images={[kitchen1, kitchen2, kitchen3, kitchen4, kitchen5, kitchen6]}
      titles={{
        mainTitle: "Kitchens",
        subTitle: "Tailor-Made Kitchen Transformations",
        sectionTitle1: "Design & Planning",
        sectionTitle2: "Installation & Refinement",
        sectionTitle3: "Completion",
        sectionTitle4: "Kitchens Gallery",
      }}
      descriptions={{
        sectionDescription1: "Your kitchen, the heart of your home, should cater to your culinary needs and aesthetic " +
          "preferences. Our design specialists collaborate with you to create a comprehensive plan, tailoring every " +
          "aspect to craft your dream kitchen.",
        sectionDescription2: "From state-of-the-art appliances to custom cabinetry and stylish countertops, we bring " +
          "your vision to life with precision and craftsmanship. We merge functionality with style, transforming your " +
          "kitchen into a space you'll love to cook in and showcase.",
        sectionDescription3: "Our dedication goes beyond installation. We provide a thorough clean-up post-renovation, " +
          "ensuring your new kitchen is ready for you to whip up a feast. We consider our job complete only when " +
          "you're fully satisfied with your personalized, kitchen transformation.",
        imageAlt: "kitchen"
      }}
      galleryLink="/gallery/kitchens"
      seo={{
        title: "Kitchen Remodeling & Renovation",
        description: "Max Renovations offers top-notch kitchen remodeling and renovation services. Discover how we transform kitchens into the heart of the home."
      }}
    />
  )
}

export default Kitchens;