import React from 'react';
import {NavLink} from "react-router-dom";

function StateTabs() {

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:flex justify-around mx-auto w-4/5 lg:border-b-2 border-beige mt-12">
      <NavLink
        to="/gallery/kitchens"
        className={({isActive}) => isActive ? `text-2xl xl:text-3xl text-beige border-b-4 border-beige py-4` : 'text-2xl xl:text-3xl text-neutral-50 py-4 hover:text-darkbeige underline lg:no-underline'}
      >
        Kitchens
      </NavLink>
      <NavLink
        to="/gallery/bathrooms"
        className={({isActive}) => isActive ? `text-2xl xl:text-3xl text-beige border-b-4 border-beige py-4` : 'text-2xl xl:text-3xl text-neutral-50 py-4 hover:text-darkbeige underline lg:no-underline'}
      >
        Bathrooms
      </NavLink>
      <NavLink
        to="/gallery/full-home"
        className={({isActive}) => isActive ? `text-2xl xl:text-3xl text-beige border-b-4 border-beige py-4` : 'text-2xl xl:text-3xl text-neutral-50 py-4 hover:text-darkbeige underline lg:no-underline'}
      >
        Full Home
      </NavLink>
      <NavLink
        to="/gallery/extensions"
        className={({isActive}) => isActive ? `text-2xl xl:text-3xl text-beige border-b-4 border-beige py-4` : 'text-2xl xl:text-3xl text-neutral-50 py-4 hover:text-darkbeige underline lg:no-underline'}
      >
        Extensions
      </NavLink>
      <NavLink
        to="/gallery/outdoors"
        className={({isActive}) => isActive ? `text-2xl xl:text-3xl text-beige border-b-4 border-beige py-4` : 'text-2xl xl:text-3xl text-neutral-50 py-4 hover:text-darkbeige underline lg:no-underline'}
      >
        Outdoors & Landscapes
      </NavLink>
      <NavLink
        to="/gallery/roofing"
        className={({isActive}) => isActive ? `text-2xl xl:text-3xl text-beige border-b-4 border-beige py-4` : 'text-2xl xl:text-3xl text-neutral-50 py-4 hover:text-darkbeige underline lg:no-underline'}
      >
        Roofing
      </NavLink>
    </div>
  );
}

export default StateTabs;